interface CustomError extends Error {
  response: Response
  data: string
}

export default async function fetcher(
  url: string,
  method?: "GET" | "POST",
  params?: Record<string, string>,
) {
  try {
    const response = await fetch(globalThis.location.origin + url, {
      method: method || "GET",
      body: params ? JSON.stringify(params) : undefined,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })

    // if the server replies, there's always some data in json
    // if there's a network error, it will throw at the previous line
    const data = await response.json()

    if (response.ok) {
      return data
    }
    const error = new Error(
      data?.message || data?.response?.message,
    ) as unknown as CustomError
    error.response = response
    error.data = data
    throw error
  } catch (error: any) {
    if (!error.data) {
      error.data = { message: error?.message }
    }
    throw error
  }
}
